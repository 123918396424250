/**
 * @flow
 *
 * @format
 */
import type { LocaleType } from 'src/store/scenario/header/ScenarioLocaleManager';
import LocalizedString from './LocalizedString';
import LocalizedFile from './LocalizedFile';
import GameAreaItem from './GameAreaItem';
import BaseItem from './BaseItem';
import POIItem from './POIItem';
import AtlObject from './AtlObject';
import type { ObjectMap } from './Shortcuts';

export type ScenarioVendingInfo = {
  comingSoon: boolean,
  externalSeller: boolean,
  visible: boolean,
  expendable: boolean,

  isFree: boolean,
  iapSku?: ?string,
  price: number,
  promoExpirationDate?: ?number,
};

export type ScenarioVersion = {
  scenarioVersion: string,
};

export type ScenarioEngineVersion = {
  [engineVersion: number]: ScenarioVersion,
};

export type MissionType = 'isOfficial' | 'isCertifiedCreator' | 'isPrivate';
export const missionTypes = {
  isOfficial: 'isOfficial',
  isCertifiedCreator: 'isCertifiedCreator',
  isPrivate: 'isPrivate',
};
const missionTypesArray = ['isOfficial', 'isCertifiedCreator', 'isPrivate'];

// isOfficial is now deprecated, we use mission type instead, this is how we handle it here
const retroCompatibilityHandleMissionType = (missionType: ?MissionType, isOfficial: ?boolean) => {
  // We check if the mission type already exists
  if (missionType && missionTypesArray.includes(missionType)) {
    return missionType;
  }
  // Retro compatibility, we check isOfficial, if it's undefined or false then it's private (default)
  return isOfficial ? 'isOfficial' : 'isPrivate';
};

export const gameModes = ['walk', 'investigation', 'treasureHunt', 'geocache', 'orienteering'];
export type GameModesType = 'walk' | 'investigation' | 'treasureHunt' | 'geocache' | 'orienteering';

export type TagsType =
  | 'historical'
  | 'educative'
  | 'committed'
  | 'sportive'
  | 'heroicFantasy'
  | 'futurist'
  | 'sf'
  | 'wtf'
  | 'fictif'
  | 'cyberPunk';
export type TagScenarioType = { label: TagsType, color: string };
export const allTags: Array<TagScenarioType> = [
  { label: 'historical', color: '#FDE700' },
  { label: 'educative', color: '#FDE700' },
  { label: 'committed', color: '#AE68F7' },
  { label: 'sportive', color: '#74CD81' },
  { label: 'heroicFantasy', color: '#3ABEFF' },
  { label: 'futurist', color: '#3ABEFF' },
  { label: 'sf', color: '#3ABEFF' },
  { label: 'wtf', color: '#74CD81' },
  { label: 'fictif', color: '#74CD81' },
  { label: 'cyberPunk', color: '#AE68F7' },
];

export default class Scenario extends AtlObject<any> {
  cityId: string;

  id: string;

  name: LocalizedString;

  teamId: string;

  startItemId: string;

  playModes: {
    realCoordinate: boolean,
    streetView: boolean,
  };

  difficulty: number;

  durationAvg: number;

  durationMin: number;

  durationMax: number;

  distanceAvg: number;

  distanceMin: number;

  distanceMax: number;

  subtitle: LocalizedString;

  pitch: LocalizedString;

  missionText: LocalizedString;

  startPointName: LocalizedString;

  startPointDescr: LocalizedString;

  scenarioEndLinkUrl: LocalizedString;

  scenarioEndLinkLabel: LocalizedString;

  accessWheelchair: boolean;

  cover: LocalizedFile;

  partners: LocalizedFile[];

  hasLightCover: boolean;

  isOfficial: boolean;

  missionType: MissionType;

  needCar: boolean;

  ageMin: number;

  cityKnowledge: boolean;

  gameArea: GameAreaItem;

  lastVersion: string;

  lastEngineVersion: string;

  versionPerEngine: ScenarioEngineVersion;

  managedLocales: LocaleType[];

  vendingInfo: ScenarioVendingInfo;

  isSchool: boolean;

  displayOrder: number;

  meta: any;

  unlockCondition: ?String;

  disableLucas: boolean;

  gameMode: GameModesType;

  tags: Array<TagScenarioType>;

  madeOnGayaWeb: boolean;

  constructor(json: any) {
    super(json);
    const {
      id,
      isOfficial,
      missionType,
      cityId,
      name,
      startItemId,
      teamId,
      playModes,
      coverFileName,
      contentFileName,
      zipFileName,
      difficulty,
      durationMax,
      durationAvg,
      durationMin,
      distanceMin,
      distanceMax,
      distanceAvg,
      subtitle,
      pitch,
      cover,
      partners,
      hasLightCover,
      missionText,
      startPoint,
      startPointName,
      startPointDescr,
      scenarioEndLinkUrl,
      scenarioEndLinkLabel,
      accessWheelchair,
      needCar,
      ageMin,
      cityKnowledge,
      gameArea,
      meta,
      lastVersion,
      lastEngineVersion,
      scenarioVersion,
      versionPerEngine,
      managedLocales,
      vendingInfo,
      isSchool,
      displayOrder,
      unlockCondition,
      disableLucas,
      gameMode,
      tags,
      madeOnGayaWeb,
      ...meta2
    } = json;
    const baseArea = gameArea || {};
    this.id = id;
    this.cityId = cityId || 'default';
    this.name = new LocalizedString(`${id}_name`, name);
    this.cover = new LocalizedFile(id, 'cover', cover, true);

    this.partners = [];
    if (partners) {
      this.partners = partners.map((img) => new LocalizedFile(id, 'partners', img, true));
    }
    this.isOfficial = isOfficial || false;
    this.missionType = retroCompatibilityHandleMissionType(missionType, isOfficial);
    this.hasLightCover = hasLightCover || false;
    this.startItemId = startItemId || 'start_poi';
    this.teamId = teamId;
    this.playModes = playModes;
    this.difficulty = difficulty;
    this.durationAvg = durationAvg;
    this.durationMin = durationMin;
    this.durationMax = durationMax;
    this.distanceAvg = distanceAvg;
    this.distanceMin = distanceMin;
    this.distanceMax = distanceMax;
    this.subtitle = new LocalizedString(`${this.id}_subtitle`, subtitle);
    this.pitch = new LocalizedString(`${this.id}_pitch`, pitch);
    this.missionText = new LocalizedString(`${this.id}_missionText`, missionText);
    this.startPointName = new LocalizedString(`${this.id}_startPointName`, startPointName, !isSchool);
    this.startPointDescr = new LocalizedString(`${this.id}_startPointDescr`, startPointDescr, !isSchool);
    this.scenarioEndLinkUrl = new LocalizedString(`${this.id}_scenarioEndLinkUrl`, scenarioEndLinkUrl, !isSchool);
    this.scenarioEndLinkLabel = new LocalizedString(`${this.id}_scenarioEndLinkLabel`, scenarioEndLinkLabel, !isSchool);
    this.accessWheelchair = accessWheelchair !== '' ? accessWheelchair : false;
    this.needCar = needCar !== '' ? needCar : false;
    this.ageMin = ageMin;
    this.cityKnowledge = cityKnowledge !== '' ? cityKnowledge : false;
    this.gameArea = baseArea.area ? new GameAreaItem(baseArea) : new GameAreaItem({ id: 'gameZone', area: gameArea });
    this.lastVersion = lastVersion;
    this.lastEngineVersion = lastEngineVersion || 2;
    this.versionPerEngine = versionPerEngine ? { ...versionPerEngine } : {};
    this.managedLocales = managedLocales ? [...managedLocales] : ['fr', 'en'];
    this.vendingInfo = vendingInfo ? { ...vendingInfo } : {};
    this.isSchool = isSchool !== '' ? isSchool : false;
    this.meta = { ...meta, ...meta2 };
    this.displayOrder = displayOrder;
    this.unlockCondition = unlockCondition;
    this.disableLucas = disableLucas !== '' ? disableLucas : false;
    this.gameMode = gameMode !== '' ? gameMode : undefined;
    this.tags = tags || [];
    this.madeOnGayaWeb = madeOnGayaWeb;
    delete this.meta.iapSku;
    delete this.meta.comingSoon;
    delete this.meta.price;
  }

  updateSku() {
    this.vendingInfo.iapSku = `com.atlantide.gayaplayer.${this.id}`;
  }

  serializeInheritedFieldsForApp(items?: ObjectMap<BaseItem>) {
    let startPoi: ?POIItem;
    if (items && items[this.startItemId] && items[this.startItemId] instanceof POIItem) {
      startPoi = items && items[this.startItemId];
    }

    // Recalculate vendingInfo (with isFree flag)
    const vendingInfo = { ...this.vendingInfo };
    if (vendingInfo.price === 0) {
      vendingInfo.iapSku = undefined;
    }

    const res = {
      cityId: this.cityId,
      name: this.name.serializeForApp(),
      isOfficial: this.isOfficial,
      missionType: this.missionType,
      startItemId: this.startItemId,
      playModes: this.playModes,
      teamId: this.teamId,
      cover: this.cover.serializeForApp(),
      hasLightCover: this.hasLightCover,
      partners: this.partners.map((it) => it.serializeForApp()),
      difficulty: this.difficulty,
      durationAvg: this.durationAvg,
      durationMin: this.durationAvg,
      durationMax: this.durationAvg,
      distanceAvg: this.distanceAvg,
      distanceMin: this.distanceAvg,
      distanceMax: this.distanceAvg,
      subtitle: this.subtitle.serializeForApp(),
      pitch: this.pitch.serializeForApp(),
      missionText: this.missionText.serializeForApp(),
      startPoint: startPoi ? { ...startPoi.coordinate } : undefined,
      startPointName: this.startPointName.serializeForApp(),
      startPointDescr: this.startPointDescr.serializeForApp(),
      scenarioEndLinkUrl: this.scenarioEndLinkUrl.serializeForApp(),
      scenarioEndLinkLabel: this.scenarioEndLinkLabel.serializeForApp(),
      accessWheelchair: this.accessWheelchair,
      needCar: this.needCar,
      ageMin: this.ageMin,
      cityKnowledge: this.cityKnowledge,
      gameArea: this.gameArea && [...this.gameArea.area],
      lastVersion: this.lastVersion,
      lastEngineVersion: this.lastEngineVersion,
      versionPerEngine: { ...this.versionPerEngine },
      managedLocales: [...this.managedLocales],
      isSchool: this.isSchool || false,
      releaseDate: new Date().getTime(),
      displayOrder: this.displayOrder,
      unlockCondition: this.unlockCondition,
      disableLucas: this.disableLucas || false,
      gameMode: this.gameMode,
      tags: this.tags,
      madeOnGayaWeb: this.madeOnGayaWeb,
      vendingInfo,
    };
    if (this.isSchool) {
      res.startPoint = {
        latitude: 0,
        longitude: 0,
      };
      res.cityId = 'default';
      delete res.startPointName;
      delete res.startPointDescr;
    }
    return res;
  }

  serializeInheritedFields(items?: ObjectMap<BaseItem>) {
    const res = this.serializeInheritedFieldsForApp(items);
    res.cover = this.cover.serialize();
    res.partners = this.partners.map((it) => it.serialize());
    return res;
  }

  getLocalizedStringsWithPath() {
    const res = super.getLocalizedStringsWithPath();
    res.name = this.name;
    res.subtitle = this.subtitle;
    res.pitch = this.pitch;
    res.missionText = this.missionText;
    res.startPointName = this.startPointName;
    res.startPointDescr = this.startPointDescr;
    res.scenarioEndLinkUrl = this.scenarioEndLinkUrl;
    res.scenarioEndLinkLabel = this.scenarioEndLinkLabel;
    return res;
  }

  getLocalizedFilesWithPath() {
    const res = {
      ...super.getLocalizedFilesWithPath(),
      cover: this.cover,
    };
    this.partners.forEach((img) => {
      res[`partners.${img.index}`] = img;
    });
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  getTranslationCsvIdPrefix() {
    return 'header';
  }
}
